<template>
  <div class="menu-body" v-if="
    $oidc.isAuthenticated &&
    $oidc.userProfile &&
    $oidc.userProfile.roles &&
    $oidc.userProfile.roles.length > 0
  ">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="main-con">
      <div class="mat-info-con border-bottom" style="display: flex">
        <div class="target-card-lable target-label">
          <div style="display: flex; vertical-align: middle; padding-top: 2px">
            Action
          </div>

          <div v-if="status != '' && status != null" class="ellipse"></div>
          <div class="pClass">{{ status }}</div>
        </div>
      </div>
      <div class="mat-info-con">
        <div class="row-level-row">
          <h4>Description</h4>

          <div class="descText" style="white-space: pre-line">
            {{ actionDescription }}
          </div>
        </div>
      </div>
      <div class="level-con desc-level">
        <div class="row" style="padding-left: 15px; justify-content: space-between">
          <div class="plant-card">
            <div class="info-card-lable info-label">
              <h4>Owner</h4>
            </div>
            <div class="lable-info-card">{{ owner }}</div>
          </div>
          <div class="plant-card">
            <div class="info-card-lable info-label">
              <h4>Committed Date</h4>
            </div>
            <div class="info-card">
              {{ target_comp_date | dateFormater }}
            </div>
          </div>
          <div class="plant-card">
            <div class="info-card-lable info-label">
              <h4>Actual Completion Date</h4>
            </div>
            <div class="info-card">
              {{ actual_comp_date | dateFormater }}
            </div>
          </div>
        </div>
      </div>

      <div class="level-con level-con-level">
        <div class="row level-row" style="justify-content: space-between; margin-top: 20px">

          <div class="row row-level-row" style="padding-left: 23px">
            <h4>History</h4>
          </div>
        </div>
      </div>
      <template>
        <div class="view-asmt border border-0 table-asmts">
          <b-table id="actionTrackerTable" responsive hover class="viewaction-table" :items="items" :fields="fields">
            <template #cell(date)="row">
              <div>{{ row.item.date | dateFormater }}</div>
            </template>
          </b-table>
          <div v-if="!view_action_item.length" class="no-data">
            <img alt="no data" src="../../../assets/No_Data.svg" />
            <span class="ed-date">No Records Found</span>
          </div>
        </div>
      </template>

      <div class="level-con" style="margin-left: 0.5rem; margin-right: 2rem">
        <div class="row level-row" style="justify-content: space-between">
          <div class="row row-level-row">
            <h4>Attachments</h4>
          </div>
        </div>
      </div>
      <div class="mat-info-con border-bottom" style="display: block; padding-top: 0px; padding-left: 33px">
        <ul class="uploaded-items">
          <li class="li-doc-class" v-for="action in view_action_attachments" :key="action.actionDocumentsId">
            <span class="uploaded-file-name">
              <a class="li-doc-class" @click="downloadItem(action)" download>{{
                action.documentUrl.split("/").pop().replaceAll("%", "_")
              }}</a>
            </span>
            <img alt="more" class="attch-icon" src="../../../assets/download 2.svg" />
          </li>
        </ul>
      </div>
      <div class="mat-info" style="text-align: right; margin: 10px">
        <b-button class="rounded-pill btn-action" pill variant="primary" @click="addMore()">Back
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Role from "../../../common/constants/roles";
import Api from "../../../common/constants/api";
import ApiCalls from "../../../common/api/apiCalls";
import Loader from "../../../components/common/Loader.vue";
//import axios from "axios";
//import dateFormater from "../../../common/filters/filters.js";
//import axios from "axios";
export default {
  name: "Action",
  data() {
    return {
      status: "",
      selected: "",
      roles: Role,
      showLoader: true,
      actionId: "",
      owner: "",
      target_comp_date: "",
      actual_comp_date: "",
      plantId: "",
      actionDescription: "",
      view_action_item: [],
      actionHistoryD: "",
      view_action_attachments: [],
      extFile: "",
      pillarId: "",
      fields: [
        {
          key: "date",
          label: "Date",
          sortable: false,
          tdClass: "border border-0",
          // thStyle: { width: "8rem" },
        },
        {
          key: "description",
          label: "Description",
          sortable: false,
          tdClass: "border border-0",
          // thStyle: { width: "12rem" },
        },

        {
          key: "status",
          label: "Status",
          sortable: false,
          tdClass: "border border-0",
          // thStyle: { width: "30rem" },
        },
      ],
      items: [
        {
          date: "",
          description: "",
          status: "",
        },
        {
          Date: "",
          Description: "",
          status: "",
        },
      ],
    };
  },

  components: {
    Breadcrumb,
    Loader,
  },

  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 3,
    });
    this.$store.dispatch("breadcrumb", {
      title: "View Action Item",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Plant Optimization Action Tracker / ", primary: false },
        { name: "View Action Item", primary: false },
      ],
    });
  },
  created() {
    this.actionId = this.$route.params.id;
    this.plantId = this.$route.params.ids;
    this.pillarId = this.$route.params.pillarId;

    this.getViewActionItems();
  },
  methods: {
    async downloadItem(obj) {
      const url = JSON.parse(JSON.stringify(obj.documentUrl));
      const label = JSON.parse(JSON.stringify(action.documentUrl.split("/").pop().replaceAll("%", "_")));
      const response = await axios.get(url, { responseType: "blob" });
      // apiCalls.httpCallDetails(url, "get").then((response) => {
      console.log(response);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
      // })

    },

    getViewActionItems() {
      let arr = [];
      if (this.plantId != 0 && this.actionId != 0) {
        let url = Api.GETVIEWACTIONITEMS + this.actionId + "/" + this.plantId;

        ApiCalls.httpCallDetails(url, "get", "arraybuffer").then((data) => {
          this.showLoader = false;
          if (data.success === true) {
            //  this.showLoader= !data.success,
            this.view_action_item = data.data.actionHistory;
            this.view_action_attachments = data.data.attachments;

            this.actionDescription = data.data.actionDescription;
            this.owner = data.data.ownerName;
            this.target_comp_date = data.data.targetCompletionDate;
            this.actual_comp_date = data.data.actualCompletionDate;
            this.status = data.data.status;

            if (this.view_action_item.length > 0) {
              this.view_action_item.forEach(function (element) {
                let actionHistoryDate = element.date;
                let actionHistoryDesc = element.description;
                let actionHistoryStatus = element.status;
                let obj = {
                  date: actionHistoryDate,
                  description: actionHistoryDesc,
                  status: actionHistoryStatus,
                };
                arr.push(obj);
              });
            }
            this.items = arr;
          }
        });
      }
    },

    addMore() {
      if (this.$route.params.screen == "action") {
        this.$router.push(
          "/action/view-action/" + this.plantId + "/" + this.pillarId
        );
      } else if (this.$route.params.screen == "assessment") {
        this.$router.go(-1);        
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
.uploaded-items {
  list-style: none;
  padding: 0;
}

.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.target-label {
  padding-left: 15px;
  display: flex;
  vertical-align: middle;
}

.btn-action {
  background: #274ba7;
  width: 65px;
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
}

.level-con-level {
  /* margin-left: 2rem; */
  margin-right: 2rem;
}

.li-doc-class {
  margin-bottom: 8px;
  color: #274ba7;
  font-family: "BarlowR", sans-serif;
  list-style: none;
  line-height: 1.9166666666666667rem;
  font-size: 1.1666666666666667rem;
}

.table-asmts {
  padding-left: 2rem;
  padding-right: 2rem;
}

.info-card {
  font-family: "BarlowR", sans-serif;
  font-size: 1.16rem;
  line-height: 1.91rem;
  text-align: center;
}

.lable-info-card {
  font-family: "BarlowR", sans-serif;
  font-size: 1.16rem;
  line-height: 1.91rem;
  text-align: left;
}

.info-label {
  font-family: "BarlowM", sans-serif;
  font-size: 1.33rem;
  line-height: 1.5rem;
  text-align: left;
}

.desc-level {
  margin-left: 1rem;
  margin-right: 2rem;
  justify-content: space-between;
}

.row-level-row {
  padding-left: 15px;
  font-family: "BarlowM", sans-serif;
  text-align: left;
  font-size: 1.3333333333333333rem;
  line-height: 1.5rem;
}

.descText {
  font-family: "BarlowR", sans-serif;
  text-align: left;
  font-size: 1.1666666666666667rem;
  line-height: 1.9166666666666667rem;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.ellipse {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  background: green;
  padding-top: 4px;
  margin-left: 15px;
  margin-top: 9px;
}

.pClass {
  color: green;
  margin-top: 6px;
  margin-left: 10px;
  font-family: "BarlowSb", sans-serif;
  font-size: 1.333rem;
  line-height: 1.4166666666666667rem;
}

.main-con {
  background: #fff;
  margin-top: 1em;
  border-radius: 6px;
}

.mat-info-con {
  padding: 2rem;
  display: flex;
}

.mat-info {
  padding: 2rem;
  /* display: flex; */
}

.plant-dd {
  width: 30%;
}

.plant-select {
  height: 54px;
  border-color: #313131;
}

.plant-card {
  background: #ffff;
  height: 54px;
  /* margin-left: 1.5em; */
  text-align: center;
  color: #414141;
  padding: 8px;
  border-radius: 7px;
}

.mm-menu {
  margin-right: 6rem;
}

.level-con {
  padding-left: 15px;
  padding-right: 105px;
}

.level-row {
  text-align: center;
  /* background: #eff3ff;
  border: 1px solid #ebecef; */
  border-radius: 5px 5px 0px 0px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 25px;
}

.level-status {
  color: #454545;
}

.level-date {
  opacity: 0.7;
}

.modify-date {
  position: absolute;
  top: 167px;
  right: 50px;
}

.dropdown-custome .dropdown-menu {
  min-width: 2rem;
}

.mat-stats-con {
  display: flex;
  padding-left: 2rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  align-items: center;
}

.mat-status-con {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

.asmt-bt {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
}

.asmt-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.last-ast-date {
  padding-top: 0.5rem;
  color: #5a5a5a;
}

.ast-action {
  display: flex;
  justify-content: center;
}

.take-ast-btn {
  margin-right: 1rem;
}

@media only screen and (max-width: 575px) {
  .mat-stats-con {
    display: block !important;
  }

  .mat-status-con {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .plant-card[data-v-dcabda2e] {
    width: 100% !important;
    margin-left: 0em !important;
    margin-top: 1em;
  }

  .modify-date {
    top: 437px !important;
  }
}
</style>

